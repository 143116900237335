import $ from 'jquery';
window.jQuery = $;
window.$ = $;

import Cookies from 'js-cookie';
window.Cookies = Cookies;

import Vue from 'vue/dist/vue.common.prod';
window.Vue = Vue;

window.copyPageUrl = function copyPageUrl() {
 navigator.clipboard.writeText(location.href);
};

let rankedin = window.rankedin || {};

rankedin.countries = {
    'RIN': 0,
    'NO': 1,
    'AD': 2,
    'AE': 3,
    'AF': 4,
    'AG': 5,
    'AO': 6,
    'AR': 7,
    'AT': 8,
    'AU': 9,
    'AZ': 10,
    'BA': 11,
    'BB': 12,
    'BD': 13,
    'BE': 14,
    'BF': 15,
    'BG': 16,
    'BH': 17,
    'BI': 18,
    'BJ': 19,
    'BN': 20,
    'BO': 21,
    'BR': 22,
    'BS': 23,
    'BT': 24,
    'BW': 25,
    'BY': 26,
    'BZ': 27,
    'CA': 28,
    'CD': 29,
    'CF': 30,
    'CG': 31,
    'CH': 32,
    'CI': 33,
    'CL': 34,
    'CM': 35,
    'CN': 36,
    'CO': 37,
    'CR': 38,
    'CU': 39,
    'CV': 40,
    'CY': 41,
    'CZ': 42,
    'DE': 43,
    'DJ': 44,
    'DK': 45,
    'DM': 46,
    'DO': 47,
    'DZ': 48,
    'EC': 49,
    'EE': 50,
    'EG': 51,
    'ER': 52,
    'ES': 53,
    'ET': 54,
    'FI': 55,
    'FJ': 56,
    'FM': 57,
    'FR': 58,
    'GA': 59,
    'GB': 60,
    'GD': 61,
    'GE': 62,
    'GH': 63,
    'GM': 64,
    'GN': 65,
    'GQ': 66,
    'GR': 67,
    'GT': 68,
    'GW': 69,
    'GY': 70,
    'HN': 71,
    'HR': 72,
    'HT': 73,
    'HU': 74,
    'ID': 75,
    'IE': 76,
    'IL': 77,
    'IN': 78,
    'IQ': 79,
    'IR': 80,
    'IS': 81,
    'IT': 82,
    'JM': 83,
    'JO': 84,
    'JP': 85,
    'KE': 86,
    'KG': 87,
    'KH': 88,
    'KI': 89,
    'KM': 90,
    'KN': 91,
    'KP': 92,
    'KR': 93,
    'KW': 94,
    'KZ': 95,
    'LA': 96,
    'LB': 97,
    'LC': 98,
    'LI': 99,
    'LK': 100,
    'LR': 101,
    'LS': 102,
    'LT': 103,
    'LU': 104,
    'LV': 105,
    'LY': 106,
    'MA': 107,
    'MC': 108,
    'MD': 109,
    'ME': 110,
    'MG': 111,
    'MH': 112,
    'MK': 113,
    'ML': 114,
    'MM': 115,
    'MN': 116,
    'MR': 117,
    'MT': 118,
    'MU': 119,
    'MV': 120,
    'MW': 121,
    'MX': 122,
    'MY': 123,
    'MZ': 124,
    'NA': 125,
    'NE': 126,
    'NG': 127,
    'NI': 128,
    'NL': 129,
    'NP': 130,
    'NR': 131,
    'NZ': 132,
    'OM': 133,
    'PA': 134,
    'PE': 135,
    'PG': 136,
    'PH': 137,
    'PK': 138,
    'PL': 139,
    'PT': 140,
    'PW': 141,
    'PY': 142,
    'QA': 143,
    'RO': 144,
    'RS': 145,
    'RU': 146,
    'RW': 147,
    'SA': 148,
    'SB': 149,
    'SC': 150,
    'SD': 151,
    'SE': 152,
    'SG': 153,
    'SI': 154,
    'SK': 155,
    'SL': 156,
    'SM': 157,
    'SN': 158,
    'SO': 159,
    'SR': 160,
    'SS': 161,
    'ST': 162,
    'SV': 163,
    'SY': 164,
    'SZ': 165,
    'TD': 166,
    'TG': 167,
    'TH': 168,
    'TJ': 169,
    'TL': 170,
    'TM': 171,
    'TN': 172,
    'TO': 173,
    'TR': 174,
    'TT': 175,
    'TV': 176,
    'TZ': 177,
    'UA': 178,
    'UG': 179,
    'US': 180,
    'UY': 181,
    'UZ': 182,
    'VC': 183,
    'VE': 184,
    'VN': 185,
    'VU': 186,
    'WS': 187,
    'YE': 188,
    'ZA': 189,
    'ZM': 190,
    'ZW': 191,
    'AX': 193,
    'AL': 194,
    'AS': 196,
    'AI': 199,
    'AM': 202,
    'AW': 203,
    'BM': 215,
    'IO': 222,
    'KY': 231,
    'CX': 236,
    'CC': 237,
    'CK': 240,
    'CW': 244,
    'FK': 260,
    'FO': 261,
    'GF': 267,
    'PF': 268,
    'TF': 269,
    'GI': 275,
    'GL': 277,
    'GP': 279,
    'GU': 280,
    'GG': 282,
    'VA': 287,
    'HK': 289,
    'IM': 297,
    'JE': 302,
    'MO': 318,
    'MQ': 326,
    'YT': 329,
    'MS': 335,
    'NC': 343,
    'NU': 348,
    'NF': 349,
    'MP': 351,
    'PN': 361,
    'PR': 364,
    'RE': 370,
    'BL': 371,
    'MF': 375,
    'PM': 376,
    'SX': 387,
    'GS': 393,
    'PS': 398,
    'SJ': 401,
    'TW': 406,
    'TK': 412,
    'TC': 418,
    'UM': 424,
    'VG': 431,
    'VI': 432,
    'WF': 433,
    'EH': 434,
    'GB-ENG': 435,
    'GB-NIR': 436,
    'GB-SCT': 437,
    'GB-WLS': 438,
    'GB-ORK': 439,
    'GB-IOW': 440,
    'SE-GOT': 441,
    'EE-SRM': 442,
    'XK': 443,
    0: "RIN",
    1: "NO",
    2: "AD",
    3: "AE",
    4: "AF",
    5: "AG",
    6: "AO",
    7: "AR",
    8: "AT",
    9: "AU",
    10: "AZ",
    11: "BA",
    12: "BB",
    13: "BD",
    14: "BE",
    15: "BF",
    16: "BG",
    17: "BH",
    18: "BI",
    19: "BJ",
    20: "BN",
    21: "BO",
    22: "BR",
    23: "BS",
    24: "BT",
    25: "BW",
    26: "BY",
    27: "BZ",
    28: "CA",
    29: "CD",
    30: "CF",
    31: "CG",
    32: "CH",
    33: "CI",
    34: "CL",
    35: "CM",
    36: "CN",
    37: "CO",
    38: "CR",
    39: "CU",
    40: "CV",
    41: "CY",
    42: "CZ",
    43: "DE",
    44: "DJ",
    45: "DK",
    46: "DM",
    47: "DO",
    48: "DZ",
    49: "EC",
    50: "EE",
    51: "EG",
    52: "ER",
    53: "ES",
    54: "ET",
    55: "FI",
    56: "FJ",
    57: "FM",
    58: "FR",
    59: "GA",
    60: "GB",
    61: "GD",
    62: "GE",
    63: "GH",
    64: "GM",
    65: "GN",
    66: "GQ",
    67: "GR",
    68: "GT",
    69: "GW",
    70: "GY",
    71: "HN",
    72: "HR",
    73: "HT",
    74: "HU",
    75: "ID",
    76: "IE",
    77: "IL",
    78: "IN",
    79: "IQ",
    80: "IR",
    81: "IS",
    82: "IT",
    83: "JM",
    84: "JO",
    85: "JP",
    86: "KE",
    87: "KG",
    88: "KH",
    89: "KI",
    90: "KM",
    91: "KN",
    92: "KP",
    93: "KR",
    94: "KW",
    95: "KZ",
    96: "LA",
    97: "LB",
    98: "LC",
    99: "LI",
    100: "LK",
    101: "LR",
    102: "LS",
    103: "LT",
    104: "LU",
    105: "LV",
    106: "LY",
    107: "MA",
    108: "MC",
    109: "MD",
    110: "ME",
    111: "MG",
    112: "MH",
    113: "MK",
    114: "ML",
    115: "MM",
    116: "MN",
    117: "MR",
    118: "MT",
    119: "MU",
    120: "MV",
    121: "MW",
    122: "MX",
    123: "MY",
    124: "MZ",
    125: "NA",
    126: "NE",
    127: "NG",
    128: "NI",
    129: "NL",
    130: "NP",
    131: "NR",
    132: "NZ",
    133: "OM",
    134: "PA",
    135: "PE",
    136: "PG",
    137: "PH",
    138: "PK",
    139: "PL",
    140: "PT",
    141: "PW",
    142: "PY",
    143: "QA",
    144: "RO",
    145: "RS",
    146: "RU",
    147: "RW",
    148: "SA",
    149: "SB",
    150: "SC",
    151: "SD",
    152: "SE",
    153: "SG",
    154: "SI",
    155: "SK",
    156: "SL",
    157: "SM",
    158: "SN",
    159: "SO",
    160: "SR",
    161: "SS",
    162: "ST",
    163: "SV",
    164: "SY",
    165: "SZ",
    166: "TD",
    167: "TG",
    168: "TH",
    169: "TJ",
    170: "TL",
    171: "TM",
    172: "TN",
    173: "TO",
    174: "TR",
    175: "TT",
    176: "TV",
    177: "TZ",
    178: "UA",
    179: "UG",
    180: "US",
    181: "UY",
    182: "UZ",
    183: "VC",
    184: "VE",
    185: "VN",
    186: "VU",
    187: "WS",
    188: "YE",
    189: "ZA",
    190: "ZM",
    191: "ZW",
    193: "AX",
    194: "AL",
    196: "AS",
    199: "AI",
    202: "AM",
    203: "AW",
    215: "BM",
    222: "IO",
    231: "KY",
    236: "CX",
    237: "CC",
    240: "CK",
    244: "CW",
    260: "FK",
    261: "FO",
    267: "GF",
    268: "PF",
    269: "TF",
    275: "GI",
    277: "GL",
    279: "GP",
    280: "GU",
    282: "GG",
    287: "VA",
    289: "HK",
    297: "IM",
    302: "JE",
    318: "MO",
    326: "MQ",
    329: "YT",
    335: "MS",
    343: "NC",
    348: "NU",
    349: "NF",
    351: "MP",
    361: "PN",
    364: "PR",
    370: "RE",
    371: "BL",
    375: "MF",
    376: "PM",
    387: "SX",
    393: "GS",
    398: "PS",
    401: "SJ",
    406: "TW",
    412: "TK",
    418: "TC",
    424: "UM",
    431: "VG",
    432: "VI",
    433: "WF",
    434: "EH",
    435: "GB-ENG",
    436: "GB-NIR",
    437: "GB-SCT",
    438: "GB-WLS",
    439: "GB-ORK",
    440: "GB-IOW",
    441: "SE-GOT",
    442: "EE-SRM",
    443: 'XK'
};

rankedin.google = {
    getCountryFromGoogleAdressComponent: function (address_components) {
        for (var i = 0; i < address_components.length; i++) {

            if (address_components[i].types == undefined) {
                continue;
            }

            for (var j = 0; j < address_components[i].types.length; j++) {
                if (address_components[i].types[j] === "country") {
                    return {
                        long: address_components[i]["long_name"],
                        short: address_components[i]["short_name"]
                    };
                }
            }
        }

        return { long: "", short: "" };
    },

    getCityFromGoogleAdressComponent: function (address_components) {

        for (var i = 0; i < address_components.length; i++) {

            if (address_components[i].types == undefined) {
                continue;
            }

            for (var j = 0; j < address_components[i].types.length; j++) {
                if (address_components[i].types[j] == "locality") {
                    return {
                        long: address_components[i]["long_name"],
                        short: address_components[i]["short_name"]
                    };
                }
            }
        }

        return { long: "", short: "" };
    },

    getCityName: function (address_components) {
        var city = this.getCityFromGoogleAdressComponent(address_components);
        return city.long;
    },

    getCountryCodeFromShortName: function (code) {
        return rankedin.countries[code.toUpperCase()];
    },

    getCountryCode: function (address_components) {
        var shortCountry = this.getCountryFromGoogleAdressComponent(address_components);
        return this.getCountryCodeFromShortName(shortCountry.short);
    }
};

window.rankedin = rankedin || {};